import SignaturePad from 'signature_pad'

$(function(){
  if ($('body')[0].className == 'people/terms' || $('body')[0].className == 'terms/create' || $('body')[0].className == 'terms/new'){
    let signature_canvas = $('#signature-canvas')[0]
    window.signaturePad = new SignaturePad(signature_canvas)

  }

window.save_signature = function(){
  if (!window.signaturePad.isEmpty()){
    $('#signature').val(window.signaturePad.toDataURL())
  }
}
})
